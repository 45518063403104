import React, { Component } from "react";
import { Helmet } from "react-helmet";


const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
   // backgroundColor: 'grey',
    justifyContent: 'center',
    margin: 10
  },
  content: {
    flex: .8,
    
    textAlign: 'center'
  }
}

class About extends Component {
  render() {
    return (
      <div style={styles.container}>
        <Helmet>
          <title>About</title>
          <meta name="description" content="About us"></meta>
        </Helmet>
        {/* FIrst Card */}
        <div style={styles.content}>
        <h1 className="display-5" style={{ color: "black" }}>
          Cointain<i> truths.</i>
        </h1>

          <p style={{ color: "black", padding: "10px" }}>
            Software companies and their products control our lives. Our goal is to build products that leverage your efforts while not manipulating users for profits.   
          </p>
        </div>
      </div>
    );
  }
}

export default About;
