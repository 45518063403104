import React from 'react';
import { Link } from "react-router-dom";
import AppleBadge from "../../img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
// import PrivacyPolicy from '../secondary/PrivacyPolicy'

const styles = {
    container: {
       display: 'flex',
        flex: 1,
        flexDirection: 'column',
      //  backgroundColor: 'grey',
        justifyContent: 'center',
        margin: 10
    },
    content: {
       flex: 1,
        textAlign: 'center'
    },
    img: {
        height: 620,
        width: 350,
        marginRight: 10,
        marginBottom: 10
    },
    button: {
        borderStyle: 'outset',
        margin: 5,
        padding: 10
      },
}

function LedgerMax () {
//    const [toggle, setToggle] = useState(false)

//   const  showHidePrivacyPolicy = () => {
//         if (toggle === true)
//         setToggle(false)
//         if (toggle === false)
//         setToggle(true)
//     }
   

    return (
        <div style={styles.container}>
            <div style={styles.content}>
            <h1> This is Ledger Max</h1>
            <Link to="/ledgermax/support" style={styles.link} >
                <button style={styles.button}>Support Page</button>
                </Link>
                <Link to="/ledgermax/privacypolicy" style={styles.link} >
                <button style={styles.button}>Privacy Policy</button>
                </Link>
            <p>Ledger Max is a bare bones tool designed to show performance of every individual Bitcoin purchase along with portfolio totals. This tool allows users to gain a more grainular control over your portfolio. </p>
            <img style={styles.img} src={require('../../img/5.5 Inch IPhone 6s.png')} alt="Ledger Max Bitcoin Price Screen"/>
            <img style={styles.img} src={require('../../img/5.5 Inch IPhone 6s_1.png')} alt="Ledger Max New Bitcoin Purchase Entry"/>
            <img style={styles.img} src={require('../../img/5.5 Inch IPhone 6s_2.png')} alt="Ledger Max Bitcoin Ledger"/>
            
            </div >
            <div style={styles.content}>
            <a href="https://apps.apple.com/us/app/ledger-max/id1542462698">
            <img src={AppleBadge} alt="Download Ledger Max on the Apple App Store Today"/>
            </a>
            </div>
           
            
          
        </div>
    )
}

export default LedgerMax;