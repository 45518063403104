import React from "react";
import { Helmet } from "react-helmet";
// import styles from "./Skills.module.css";

const styles = {
  container: {
    display: 'flex',
  height: '100vh',
  width: '100vw',
    flexDirection: "column",
   // backgroundColor: 'grey',
    margin: 10,
    alignItems: 'center',
    
  },
  content: {
    display: 'flex',
    flexDirection: "column", 
    alignItems: 'center',
    padding: 10,
    textAlign: 'center'
  }
}

export default () => {

  return (
    <div style={styles.container}>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Contact us today"></meta>
      </Helmet>

      <div style={styles.content}>
        <p>
          Email: <i>James Hawkins, Managing Member, Founder, CEO & CTO:</i>{" "}
          <a
            href="mailto:jamesdonhawkins@gmail.com?subject=Contacting from pixelandprocessor.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            jamesdonhawkins@gmail.com
          </a>
        </p>
        {/* <form>
          <label>
            Name:
            <input type="text" name="name" />
          </label>
          <input type="submit" value="Submit" />
          <br />
          <label>
            Email:
            <input type="text" name="Email" />
          </label>
          <input type="submit" value="Submit" />
        </form> */}
      </div>

      
    </div>
  );
  /**
   * 
   *       <div className="row ">
        <span className="badge badge-light">HTML</span>
        <span className="badge badge-light">CSS</span>
        <span className="badge badge-light">JavaScript</span>
        <span className="badge badge-light">Bash</span>
        <span className="badge badge-light">React</span>
        <span className="badge badge-light">Blockstack.js</span>
        <span className="badge badge-light">Express.js</span>
        <span className="badge badge-light">Node.js</span>
        <span className="badge badge-light">Heroku</span>
        <span className="badge badge-light">Netlify</span>
        <span className="badge badge-light">MongoDB</span>
        <span className="badge badge-light">Mongoose.js</span>
        <span className="badge badge-light">Gaia</span>
        <span className="badge badge-light">Git</span>
        <span className="badge badge-light">Github</span>
      </div>
   * 
   * 
   * 
   */
};
