import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

//====== CSS Modules =========================//
import styles from "./App.module.css";
//============= COMPONENTS ===========//
import Contact from "./Components/secondary/Contact";
import About from "./Components/secondary/About";
import Landing from "./Components/main/Landing";
import NavBar from "./Components/main/NavBar";
import Footer from "./Components/main/Footer";
import LedgerMax from "./Components/main/LedgerMax"
import Support from "./Components/main/Support"
import PrivacyPolicy from "./Components/secondary/PrivacyPolicy";

function App() {
  return (
    <Router>
      <div className={` ${styles.App}`}>
        <div style={{ padding: "6px" }}>
          <header className={styles.appHeader}>
            <NavBar />
          </header>
        </div>

        <Route exact path="/" component={Landing} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/ledgermax" component={LedgerMax} />
        <Route exact path="/ledgermax/support" component={Support} />
        <Route exact path="/ledgermax/privacypolicy" component={PrivacyPolicy} />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
