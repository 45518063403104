import React from "react";


const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: "column",
    flex: 1
   
  },
  content: {
    display: 'flex',
    flexDirection: "column", 
   // alignItems: 'center',
    padding: 10,
    textAlign: 'center'
  },
  subHeading: {
    fontFamily: "Courier New", 
  }
}

function PrivacyPolicy() {
  return (
    <div style={styles.container}>
      
      <div style={styles.content}>
      

       
        <h3 style={styles.heading}>Privacy Policy</h3>
        <p style={styles.subHeading}>We do not collect any personal data!</p>
        </div>
    </div>
  );
}

export default PrivacyPolicy;
