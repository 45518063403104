import React, { Component } from "react";
import { Link } from "react-router-dom";

const styles = {
  container: {
    display: 'flex',
    flex: 1
  },
  navitem: {
   marginRight: 10
  }
}

class NavBar extends Component {

  render() {
    return (
      /**++++++++++++++++++++++ */

      <nav className="navbar navbar-expand-sm navbar-light   ">
        <div className="container">
          <Link className="navbar-brand"  to="/">
            {"Pixel and Processor"}
            
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#mobile-nav"
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/** */}
          <div className="collapse navbar-collapse" id="mobile-nav">
            <ul
              className="nav navbar-nav mr-auto  "
              //style={{ padding: "15px", paddingRight: "2.5rem" }}
            >
              <li className="nav-item" >
                <Link className="nav-link" to="/about" style={styles.navitem}>
                  About
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link " to="/contact" style={styles.navitem}>
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " to="/ledgermax" style={styles.navitem}>
                  Ledger Max
                </Link>
              </li>

              {/** 
              <li className="nav-item">
                <Link className="nav-link" to="/portfolio">
                  Portfolio
                </Link>
              </li>
            
              <li className="nav-item">
                <Link className="nav-link" to="/thoughts">
                  Thoughts
                </Link>
              </li>
              */}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
