import React from 'react';

const styles = {
    container: {
       display: 'flex',
        flex: 1,
        flexDirection: "column",
        justifyContent: 'center',
        margin: 10
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: "column",
        textAlign: 'center'
    },
    form: {
        display: 'flex',
        flexDirection: "column",
        margin: '40px'
    },
    img: {
        height: 620,
        width: 350,
        marginRight: 10,
        marginBottom: 10
    }
}

function Support () {
    return (
        <div style={styles.container}>
            <div style={styles.content}>
                    <h1>Ledger Max Support</h1>
                    <p>Ledger Max is a version 1.0, there will undoubly be errors and bugs that will occure. We thank you for your support in downloading the app and taking time to seek out support.</p>
                <form style={styles.form} name="contact" action="/contact" method="post">
                    <input type="hidden" name="form-name" value="contact" />
                    <input required type="text" name="name" placeholder="Your name" />
                    <input required type="email" name="email" placeholder="Your email" />
                    <textarea required name="message" placeholder="Message" cols="40" rows="10" ></textarea>
                    <button type="submit">Send a message</button>
                </form>
            </div>   
        </div>
    )
}

export default Support;