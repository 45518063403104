import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    alignItems: 'center',
    flexDirection: "column",
  
   
  },
  content: {
    display: 'flex',
    flexDirection: "column", 
   // alignItems: 'center',
    padding: 10,
    textAlign: 'center'
  },
  
  img: {
    height: 200,
    width: 350
  },
  button: {
    borderStyle: 'outset',
    padding: 5
  },
  card: {
    borderStyle: 'outset',
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    height: 200,
    width: 200
  },
  link: {
   
    color: 'black'
  },
  heading: {
    margin: 5,
   
  },
  subHeading: {
    fontFamily: "Courier New", 
  }
}

function Landing() {
  return (
    <div style={styles.container}>
      <Helmet>
        <title>Pixel and Processor</title>
        <meta name="description" content="Built to last"></meta>
      </Helmet>
      {/* <div className={`jumbotron jumbotron-fluid ${styles.jumbotron}`}>here</div> */}
      <div style={styles.content}>
      

        {/* <span style={{ fontSize: "12px", color: "#09d3ac" }}>
          Enough with the not being industrious.
        </span> */}
<img style={styles.img} src={require('../../img/web-pixel-logo-01.png')} alt="Pixel and Processor infinity mobius strip logo"/>
        <h3 style={styles.heading}>Pixel and Processor, A Software Co.</h3>
        <p style={styles.subHeading}>Disciplined.</p>
        </div>
        
          <Link to="/ledgermax" style={styles.link} >
          <button style={styles.button}>Our Software</button>
          </Link>

       
     
    </div>
  );
}

export default Landing;
